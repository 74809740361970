import React from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { FormControlStyled, FormLabelStyled } from './styles'
import {
  useToolbarFiltersActions,
  useToolbarFiltersState,
} from '../toolbar-filters-store'
import { useRouter } from 'next/router'
import { PagePathConstant } from '@lib/constants'

function useRemoveDrilldownQuery() {
  const router = useRouter()

  return () => {
    router.replace(PagePathConstant.OVA_SERVER_INVENTORY_TABLE, undefined, {
      shallow: true,
    })
  }
}

export function ServersStatusCheckboxGroup() {
  const { statusesList } = useToolbarFiltersState()

  const { setStatusesList } = useToolbarFiltersActions()

  const removeDrilldownQuery = useRemoveDrilldownQuery()

  const handleStatusesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    removeDrilldownQuery()
    setStatusesList({
      ...statusesList,
      [e.target.name]: e.target.checked,
    })
  }

  return (
    <FormControlStyled>
      <FormLabelStyled>Status</FormLabelStyled>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="INFECTED" // @polyakh, can you please suggest how to use const here?
              checked={statusesList.INFECTED}
              onChange={handleStatusesChange}
            />
          }
          label="Infected"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="CLEAN" // @polyakh, can you please suggest how to use const here?
              checked={statusesList.CLEAN}
              onChange={handleStatusesChange}
            />
          }
          label="Healthy"
        />
      </FormGroup>
    </FormControlStyled>
  )
}
