import React, { useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { DisabledStateWrapper } from '@components/table/table-infinite-scroll-memory'
import { Toolbar } from './toolbar'
import { columnDefinitions } from './columns-definition/columns-definition'
import Box from '@mui/material/Box'
import {
  useOvaServersStoreActions,
  useOvaServersStoreState,
  useIsLoadingOvaServers,
} from './ova-servers-store'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { useRouter } from 'next/router'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from './toolbar/consts'
import { ExportAsCsv } from '@features/common'
import {
  OVA_CSV_TABLE_HEADERS,
  prepareOVADataForCsv,
} from '@components-complex/dashboard-pages-v3/ova-server-table/prepare-data-for-ova-csv'

function OvaServerTable() {
  const { fetchNextPage, fetchInitial } = useOvaServersStoreActions()

  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  const router = useRouter()

  const isDrilldownQueryActive = router.query[STATUS_FILTER_DRILLDOWN_QUERY_KEY]

  const { ovaServers, totalAccounts, totalProviders, totalServers } =
    useOvaServersStoreState()

  const isLoadingOvaServers = useIsLoadingOvaServers()

  const observable = useInfiniteScrollDataFetch({
    initialItems: ovaServers,
    fetchNextBatch: fetchNextPage,
  })

  const { ovaAccountsList, isOvaAccountsLoading } = useFetchListOvaAccounts()

  const isTableDataLoading = isLoadingOvaServers || isOvaAccountsLoading

  useEffect(() => {
    if (isDrilldownQueryActive) {
      return
    }

    fetchInitial({
      accountIdsList: sliceOvaAccountsList,
      backupProvidersList: sliceOvaBackupProvidersList,
    })
  }, [isDrilldownQueryActive])

  return (
    <DisabledStateWrapper isDisabled={false}>
      <Box display="grid" rowGap={2}>
        <Toolbar ovaAccountsList={ovaAccountsList} />
        <ExportAsCsv
          data={prepareOVADataForCsv(ovaServers, ovaAccountsList)}
          headers={OVA_CSV_TABLE_HEADERS}
          filename="servers-inventory.csv"
          shouldShow={ovaServers.length > 0}
        />
        <Table
          data={ovaServers}
          columns={columnDefinitions({
            ovaAccountsList,
            totalServers,
            totalAccounts,
            totalProviders,
          })}
          advancedProps={{
            observableState: observable,
          }}
          data-testid="recoveryPointsTable"
          generalProps={{
            noDataMessage: 'No Servers found',
            isLoading: isTableDataLoading,
          }}
        />
      </Box>
    </DisabledStateWrapper>
  )
}

export default OvaServerTable
