/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProvider } from 'blues-corejs/dist'

const backupProviderStringMap: Readonly<Record<OvaBackupProvider, string>> = {
  [OvaBackupProvider.COHESITY]: 'Cohesity',
  [OvaBackupProvider.COMMVAULT]: 'Commvault',
  [OvaBackupProvider.VEEAM]: 'Veeam',
  [OvaBackupProvider.VERITAS_NET_BACKUP]: 'VeritasNetBackup',
  [OvaBackupProvider.RUBRIK]: 'Rubrik',
}
class OvaBackupProviderHelper {
  static getProviderName(provider: OvaBackupProvider): string {
    return backupProviderStringMap[provider]
  }
}

export default OvaBackupProviderHelper
