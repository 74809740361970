/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { useEffect } from 'react'
import { subscribeWithSelector } from 'zustand/middleware'
import { ObjHelper } from '@lib/helpers'
import { useOvaServersStoreActions } from '../ova-servers-store'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'
import { OvaAccount, OvaBackupProvider } from 'blues-corejs/dist'

type StatusesState = Record<keyof typeof OvaServerStatus, boolean>

function convertStatusesStateToEnum(statusesList: StatusesState) {
  return Object.entries(statusesList).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(OvaServerStatus[key as keyof typeof OvaServerStatus])
    }

    return acc
  }, [] as Array<OvaServerStatus>)
}

interface ToolbarFiltersStoreState {
  ovaAccountsList: Array<OvaAccount>
  backupProvidersList: Array<OvaBackupProvider>
  statusesList: StatusesState
  textSearch: string
}

const INITIAL_STATE: ToolbarFiltersStoreState = {
  ovaAccountsList: [],
  backupProvidersList: [],
  statusesList: {
    INFECTED: false,
    CLEAN: false,
  },
  textSearch: '',
}

interface ToolbarFiltersStoreActions {
  setToolbarFiltersState: (state: Partial<ToolbarFiltersStoreState>) => void
  setTextSearch: (textSearch: string) => void
  setStatusesList: (statusesList: StatusesState) => void
  setInfectedStatus: () => void
  setHealthyStatus: () => void
  setAccountsList: (accountsIdsList: Array<OvaAccount>) => void
  setBackupProvidersList: (
    backupProvidersList: Array<OvaBackupProvider>
  ) => void
  resetToolbarFilters: () => void
}

function getPossibleOvaProviders(ovaAccountsList: Array<OvaAccount>) {
  const backupProvidersMap = new Set<OvaBackupProvider>()
  ovaAccountsList.forEach((ovaAccount) => {
    ovaAccount.backupProvidersList.forEach((backupProvider) => {
      if (!backupProvidersMap.has(backupProvider)) {
        backupProvidersMap.add(backupProvider)
      }
    })
  })

  return Array.from(backupProvidersMap)
}

const useToolbarFiltersStore = create<
  ToolbarFiltersStoreState & { actions: ToolbarFiltersStoreActions }
>()(
  subscribeWithSelector((set, get) => ({
    ...INITIAL_STATE,
    actions: {
      setToolbarFiltersState: (state: Partial<ToolbarFiltersStoreState>) =>
        set(state),
      setTextSearch: (textSearch: string) => set({ textSearch }),
      setStatusesList: (statusesList: StatusesState) => set({ statusesList }),
      setAccountsList: (ovaAccountsList: Array<OvaAccount>) => {
        const { backupProvidersList } = get()

        if (ovaAccountsList.length) {
          const possibleOvaProviders = getPossibleOvaProviders(ovaAccountsList)

          set({
            backupProvidersList: backupProvidersList.filter((provider) =>
              possibleOvaProviders.includes(provider)
            ),
          })
        }

        set({
          ovaAccountsList,
        })
      },
      setBackupProvidersList: (backupProvidersList: Array<OvaBackupProvider>) =>
        set({ backupProvidersList }),
      resetToolbarFilters: () => set({ ...INITIAL_STATE }),
      setInfectedStatus: () =>
        set((state) => ({
          statusesList: {
            ...state.statusesList,
            INFECTED: true,
            CLEAN: false,
          },
        })),
      setHealthyStatus: () =>
        set((state) => ({
          statusesList: {
            ...state.statusesList,
            INFECTED: false,
            CLEAN: true,
          },
        })),
    },
  }))
)

export const useToolbarFiltersActions = () =>
  useToolbarFiltersStore(useShallow((state) => state.actions))

export const useToolbarFiltersState = () =>
  useToolbarFiltersStore(
    useShallow((state) => ({
      textSearch: state.textSearch,
      backupProvidersList: state.backupProvidersList,
      statusesList: state.statusesList,
      ovaAccountsList: state.ovaAccountsList,
    }))
  )

export const useToolbarFiltersSubscription = () => {
  const { fetchInitial } = useOvaServersStoreActions()

  useEffect(() => {
    const unsubscribeFn = useToolbarFiltersStore.subscribe(
      (state) => ({
        textSearch: state.textSearch,
        accountIdsList: state.ovaAccountsList,
        backupProvidersList: state.backupProvidersList,
        statusesList: state.statusesList,
      }),
      ({
        textSearch,
        accountIdsList: accountIdsList,
        backupProvidersList,
        statusesList,
      }) => {
        fetchInitial({
          textSearch,
          accountIdsList: accountIdsList.map((account) => account.id),
          statusesList: convertStatusesStateToEnum(statusesList),
          backupProvidersList,
        })
      },
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])
}
