/* eslint-disable import/no-extraneous-dependencies */
import { ListOvaAccountsClient } from '@lib/clients/ova-accounts'
import SystemHelper from '@lib/helpers/system.helper'
import { OvaAccount } from 'blues-corejs/dist'
import { useEffect, useState } from 'react'

const listOvaAccountsClient = new ListOvaAccountsClient()

export interface ListOvaAccountsOutput {
  ovaAccountsList: Array<OvaAccount>
  isOvaAccountsLoading: boolean
}

/**
 * Custom hook to get Ova Accounts.
 * @returns list of OvaAccounts and loading state
 */
export function useFetchListOvaAccounts(): ListOvaAccountsOutput {
  const [ovaAccountsList, setOvaAccountsList] = useState<Array<OvaAccount>>([])
  const [isOvaAccountsLoading, setIsOvaAccountsLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    async function fetchOvaAccountsList() {
      setIsOvaAccountsLoading(true)

      try {
        const response = await listOvaAccountsClient.getListOvaAccounts()

        if (!isMounted) {
          return
        }

        setOvaAccountsList(response)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong in ${__filename}: ${error}`
        )
        console.error(
          `Error occurred in file ${__filename} while processing: ${error}`
        )
      } finally {
        setIsOvaAccountsLoading(false)
      }
    }

    fetchOvaAccountsList()

    return () => {
      isMounted = false
      setIsOvaAccountsLoading(false)
      setOvaAccountsList([])
    }
  }, [])

  return {
    ovaAccountsList,
    isOvaAccountsLoading,
  }
}
