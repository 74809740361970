import { useRouter } from 'next/router'

import { PagePathConstant } from '@lib/constants'

interface UseServersDrilldownLink {
  handleRedirectWithDrilldown: () => void
  url?: string
}

interface Props {
  queryKey: string
  queryValue: string
}

export function useServersDrilldownLink({
  queryKey,
  queryValue,
}: Props): UseServersDrilldownLink {
  const router = useRouter()

  const url = `${PagePathConstant.OVA_SERVER_INVENTORY_TABLE}&${queryKey}=${queryValue}`

  const handleWidgetClick = () => {
    router.push(url)
  }

  return {
    handleRedirectWithDrilldown: handleWidgetClick,
    url,
  }
}
