/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  createColumn,
  StatusCircle,
  CellWithLink,
  HeaderWithLabel,
} from '@components/table'
import InfectedServerCell from './infected-server-cell'
import { OvaServer, OvaBackup, OvaAccount } from 'blues-corejs/dist/models'
import { PageHelper } from '@lib/helpers'
import { MaybeInfectedServer } from '@lib/clients/assets/list-ova-servers'
import { LastCleanBackupCell } from './last-clean-backup-cell'
import { OvaBackupProviderHelper } from '@lib/helpers/ova-backup'
import { getOvaServerAccountName, getOvaServerThreats } from '../utils'

type InputData = {
  ovaAccountsList: Array<OvaAccount>
  totalServers: number
  totalAccounts: number
  totalProviders: number
}
const buildAssetPageUrl = PageHelper.buildAssetPageUrl

export function columnDefinitions({
  ovaAccountsList,
  totalServers,
  totalProviders,
  totalAccounts,
}: InputData) {
  return [
    createColumn<MaybeInfectedServer>('hasMalware', {
      enableSorting: false,
      id: 'status-circle',
      cell: ({ row: { original } }) => {
        const isHealthy = !original.hasMalware && !original.hasRansomware

        return <StatusCircle isHealthy={isHealthy} />
      },
      size: 45,
    }),
    createColumn<MaybeInfectedServer>('server', {
      header: () => (
        <HeaderWithLabel title="Server" label={String(totalServers)} />
      ),
      enableSorting: false,
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        const assetPageLink = buildAssetPageUrl(server.id)

        return <CellWithLink href={assetPageLink} value={server.name} />
      },
    }),
    createColumn<MaybeInfectedServer>('server', {
      enableSorting: false,
      id: 'account-name',
      header: () => (
        <HeaderWithLabel title="Account" label={String(totalAccounts)} />
      ),
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        return getOvaServerAccountName(ovaAccountsList, server.ovaAccountId)
      },
    }),
    createColumn<MaybeInfectedServer>('server', {
      id: 'backup-vendor',
      enableSorting: false,
      header: () => (
        <HeaderWithLabel title="Backup Vendor" label={String(totalProviders)} />
      ),
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        return OvaBackupProviderHelper.getProviderName(server.backupProvider)
      },
    }),
    createColumn<MaybeInfectedServer>('lastCleanBackup', {
      header: 'Last Clean Recovery Point',
      enableSorting: false,
      meta: {
        textAlign: 'right',
      },
      cell: ({ getValue }) => {
        const lastCleanBackup = getValue() as unknown as OvaBackup | undefined

        return <LastCleanBackupCell backup={lastCleanBackup} />
      },
    }),
    createColumn<MaybeInfectedServer>('hasMalware', {
      id: 'status',
      header: 'Status',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const isInfected = original.hasMalware || original.hasRansomware

        return <InfectedServerCell isInfected={isInfected} />
      },
    }),
    createColumn<MaybeInfectedServer>('hasMalware', {
      header: 'Threats',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { hasMalware, hasRansomware } = original

        return getOvaServerThreats(hasMalware, hasRansomware)
      },
    }),
  ]
}
